import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Plus, TrashFill, PencilFill, Search } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";
import RemoveModal from "./RemoveModal";
import EditModal from "./EditModal";
import { ReactComponent as VoiceIcon } from "../assets/image/voice.svg";
import { ReactComponent as TextIcon } from "../assets/image/pen.svg";


const Dictionary = (props) => {
  const [show, setShow] = useState(false);
  const [editModeModal, setEditModeModal] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteModeModal, setDeleteModeModal] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [didUpdate, setDidUpdate] = useState(false);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const filteredItems = props.dictionary.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <div className="search-block">
        <input
          id="search"
          type="text"
          placeholder="Filtrer par texte d'origine"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => {
            onFilter(e);
            setTimeout(() => {
              document.getElementById("search").focus();
            }, 0);
          }}
        />
        <Search className="search-icon"/>
      </div>
      <Button
        variant="primary"
        onClick={handleShow}
        className="btn-circle add-line-btn"
      >
        <Plus />
      </Button>
    </>
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const insertAudioText = (text) => {
    props.audioText(text);
  };

  const columns = [
    {
      name: "Id",
      selector: "_id",
      omit: true,
    },
    {
      name: "Texte \nd'origine",
      selector: "name",
      maxWidth: "120px",
      sortable: true,
      sortFunction: (rowA, rowB) =>
        rowA.name.localeCompare(rowB.name, undefined, {
          numeric: true,
          sensitivity: "base",
        }),
    },
    {
      name: "Texte \naudio",
      selector: "audioText",
      maxWidth: "120px",
      sortable: true,
      sortFunction: (rowA, rowB) =>
        rowA.audioText.localeCompare(rowB.audioText, undefined, {
          numeric: true,
          sensitivity: "base",
        }),
      cell: (row) => (
        <div
          onClick={(e) => {
           // e.preventDefault();
            insertAudioText(row.audioText);
          }}
          className="text-to-insert"
        >
          {row.audioText}
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <PencilFill
            onClick={(e) => {
              e.preventDefault();
              setEditModeModal(row._id);
              setShowEditModal(true);
            }}
            className="icon-btn"
          />
          <TrashFill
            onClick={(e) => {
              e.preventDefault();
              setDeleteModeModal(row._id);
              setShowDeleteModal(true);
            }}
            className="icon-btn delete-btn"
          />
        </div>
      ),
      width: "90px",
    },
  ];

  useEffect(() => {
    fetch(process.env.REACT_APP_URL_BACKEND + "/api/dictionary", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        props.setDictionary(data);
        setDidUpdate(false);
      })
      .catch(function (error) {
        console.log("Error getting dictionary : " + error.message);
      });
  }, [didUpdate === true]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    setDidUpdate(true);
    event.preventDefault();
    const form = event.currentTarget;
    setShow(false);

    fetch(process.env.REACT_APP_URL_BACKEND + "/api/dictionary", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: form.name.value,
        audioText: form.audioText.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Line created : " + data);
        setDidUpdate(false);
      })
      .catch(function (error) {
        console.log("Error creating the line : " + error.message);
      });
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={filteredItems}
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        pagination
        paginationPerPage={10}
        paginationComponentOptions={{
          noRowsPerPage: true,
          rangeSeparatorText: "sur",
        }}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Ajouter un nouvel élément dans votre dictionnaire</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>
                <TextIcon className="label-icon" />
                Texte d'origine
              </Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
            <Form.Group controlId="audioText">
              <Form.Label>
                <VoiceIcon className="label-icon" />
                Texte audio
              </Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
            <Button className="postion-action-btn action-btn" type="submit">
              Ajouter
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <EditModal
        {...props}
        line={props.dictionary.filter((elt) => elt._id === editModeModal)}
        update={(data) => setDidUpdate(data)}
        showEditModal={showEditModal}
        show={(data) => setShowEditModal(data)}
      />
      <RemoveModal
        {...props}
        id={deleteModeModal}
        update={(data) => setDidUpdate(data)}
        showDeleteModal={showDeleteModal}
        show={(data) => setShowDeleteModal(data)}
      />
    </>
  );
};

export default Dictionary;

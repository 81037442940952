import React, { useEffect, useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ReactComponent as RotationLock } from "../assets/image/rotation-lock.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../assets/image/SDNC.png";
import logoWhite from "../assets/image/SDNC_White.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import passwordValidator from "password-validator";
import { useHistory } from "react-router-dom";

const FirstCo = (props) => {
  const [values, setValues] = React.useState({
    password: '',
    passwordTwo: '',
    showPassword: false,
    showPasswordTwo: false,
  });
  const [alertFailedFirstCo, setAlertFailedFirstCo] = useState(false);
  const [alertPwdDiff, setAlertPwdDiff] = useState(false);
  const [alertWrongFormat, setAlertWrongFormat] = useState(false);

  let history = useHistory();

    const schema = new passwordValidator();
    schema
    .is().min(12)                                   // Minimum length 8
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits(1)                                // Must have at least 1 digits
    .has().symbols(1)                               // Must have at least 1 symbols 
    .has().not().spaces();                          // Should not have spaces

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const email = localStorage.getItem("email");

    if(values.password != values.passwordTwo) {
        setAlertPwdDiff(true);
    }
    else if (schema.validate(values.password) === false) {
        console.log(schema.validate(values.password, { list: true }));
        setAlertWrongFormat(true);
    }
    else {
        console.log("Trying to update: Email = " + localStorage.getItem("email"));
        fetch(process.env.REACT_APP_URL_BACKEND + "/api/user/" + email, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            email: email,
            password: values.password,
            passwordTmp: null,
            firstCo: false,
        }),
        })
        .then((response) => {
            if (response.status === 201) {
                response.json().then(function(data) {
                    console.log("Update success");
                    localStorage.setItem("firstCo", false);
                    history.push({
                        pathname: "/app",
                    });
                });
                }
            else {
                setAlertFailedFirstCo(true);
                console.log("firstCo failed");
                console.log(response);
            }
        })
        .catch(function (error) {
            setAlertFailedFirstCo(true);
            console.log("firstCo failed : " + error.message);
        });
    }
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setAlertFailedFirstCo(false);
    setAlertPwdDiff(false);
    setAlertWrongFormat(false);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowPasswordTwo = () => {
    setValues({ ...values, showPasswordTwo: !values.showPasswordTwo });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container fluid>
      <Row className="header">
        <Col sm={2} md={2} xl={2}>
          {(localStorage.getItem("theme") === "LIGHT" || localStorage.getItem("theme") === null) ?
              (<img src={logo} className="logoHeader" alt="logo" />)
              : (
                <img src={logoWhite} className="logoHeader" alt="logo" />
              )
            }
          </Col>
        <Col sm={7} md={7} xl={7}>
          <div className="titleHeader">Calliope</div>
        </Col>
      </Row>
      <div className="loginDiv">
        <span className="title-block">Veuillez changer votre mot de passe</span>
        <div className="blue-bar"></div>
        <div class="content-changePwd">
            <div class="connexion">
              <RotationLock className="lock-img">
              </RotationLock>
            </div>
            <Form onSubmit={handleSubmit}>
                <FormControl className="password">
                    <InputLabel htmlFor="standard-adornment-password">Nouveau mot de passe</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        autoComplete="new-password"
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility-btn"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl className="password">
                    <InputLabel htmlFor="standard-adornment-password">Confirmer le mot de passe</InputLabel>
                    <Input
                    id="standard-adornment-passwordTwo"
                    type={values.showPasswordTwo ? 'text' : 'password'}
                    value={values.passwordTwo}
                    onChange={handleChange('passwordTwo')}
                    autoComplete="new-password"
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility-btn"
                            onClick={handleClickShowPasswordTwo}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {values.showPasswordTwo ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        </InputAdornment>
                    }
                    />
                </FormControl>
              <Button type="submit" className="action-btn login-btn">
                Valider
              </Button>
            </Form>
        </div>
      </div>
      <div class="alert-auth"> 
        {alertFailedFirstCo
            ? (<Alert severity="error">Mot de passe invalide. Merci de bien vouloir réessayer.</Alert>)
            : null}
      </div>
      <div class="alert-auth"> 
        {alertPwdDiff
            ? (<Alert severity="error">Mots de passe différents. Merci de bien vouloir réessayer.</Alert>)
            : null}
      </div>
      <div class="alert-auth"> 
        {alertWrongFormat
            ? (<Alert severity="error">Le mot de passe doit être de 12 caractères, avec minuscule, majuscule, chiffre et caractère spécial.</Alert>)
            : null}
      </div>
  </Container>
  );
}

export default FirstCo;
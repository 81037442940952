import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";

const AudioParam = ({ elocSpeed, setElocSpeed }) => {
  return (
    <>
      <div>
        <span className="params-label">Vitesse d'élocution :</span>
        <span className="params-label value"> {elocSpeed}</span>
        <input
          type="range"
          min="0"
          max="0.4"
          step="0.1"
          value={elocSpeed}
          className="slider rangeElocSpeed"
          onClick={(e) => e.preventDefault()}
          onChange={(e) => setElocSpeed(e.target.value)}
        ></input>
      </div>
    </>
  );
};

export default AudioParam;

import React from "react";
import { Redirect } from "react-router-dom";

function Logout(props) {
  localStorage.removeItem("token");
  localStorage.removeItem("email");
  localStorage.removeItem("role");
  //localStorage.removeItem("theme");

  return <Redirect to="/login" />;
}

export default Logout;
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

const RemoveUser = (props) => {


  const handleClose = () => props.show(false);

  const handleSubmit = () => {
    props.show(false);

    fetch(process.env.REACT_APP_URL_BACKEND + "/api/user/" + props.id, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: localStorage.getItem("email"),
        role: localStorage.getItem("role"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("User deleted : " + data);
        props.update(true);
      })
      .catch(function (error) {
        console.log("Error deleting the user : " + error.message);
      });
  };

 

  return (
    <>

      <Modal
        show={props.showDeleteModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><h5>Voulez-vous supprimer cet utilisateur ?</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Button className="submit-action-btn action-btn" onClick={(e) => {e.preventDefault(); handleSubmit()}} >Supprimer</Button>
            <Button className="postion-action-btn action-btn" onClick={(e) => {e.preventDefault(); handleClose()}}>Annuler</Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RemoveUser;

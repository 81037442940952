import ReactQuill, { Quill } from "react-quill";
import "./QuillEditor.css";
import "react-quill/dist/quill.snow.css";
import undoImg from "../assets/image/left-arrow.svg";
import pasteImg from "../assets/image/paste.svg";
import startReadingImg from "../assets/image/location-mark.svg";
import elocSpeedImg from "../assets/image/arrows.svg";
import highlightImg from "../assets/image/highlighter.svg";
import listenImg from "../assets/image/ear.svg";
import insertImg from "../assets/image/insert.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

import React from "react";

const CustomUndo = () => (
  <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip id="button-tooltip">Annuler</Tooltip>}
  >
    <img src={undoImg} alt="undo image" className="left-arrow"></img>
  </OverlayTrigger>
);
const CustomPaste = () => (
  <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip id="button-tooltip">Coller</Tooltip>}
  >
    <img src={pasteImg} alt="paste image" className="paste"></img>
  </OverlayTrigger>
);
const StartReading = () => (
  <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip id="button-tooltip">Début de la lecture</Tooltip>}
  >
    <img
      src={startReadingImg}
      alt="start reading image"
      className="location-m"
    ></img>
  </OverlayTrigger>
);
const ElocSpeed = () => (
  <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip id="button-tooltip">Désigner une vitesse d'élocution</Tooltip>}
  >
    <img src={elocSpeedImg} alt="pause image" className="para"></img>
  </OverlayTrigger>
);

function highlightTextPos(context, pos, text, rgb) {
  if (localStorage.getItem("theme") && localStorage.getItem("theme") === "DARK")
    context.formatText(pos, text.length, {'background': rgb, 'color': 'black'});
  else
    context.formatText(pos, text.length, {'background': rgb});
}

function replaceTextPos(context, pos, oldTxt, newTxt) {
  context.setSelection(pos, oldTxt.length);
  addTextOnSelectionOrPosition(context, newTxt);
}

//BUG : if the editor's last char is a highlight char, any char addition will be highlighted
function markText(context, text, rgb) {
  const editorText = context.getText();
  const re = new RegExp(`\\b${text}\\b`);
  var pos = editorText.search(re);
  var txt = editorText;
  var i = 0;
  while (pos != -1) {
    highlightTextPos(context, pos+i, text, rgb);
    i = pos + text.length + i;
    txt = editorText.slice(i);
    pos = txt.search(re);
  }
}

function replaceText(context, oldTxt, newTxt) {
  const editorText = context.getText();
  const re = new RegExp(`\\b${oldTxt}\\b`);
  var txt = editorText;
  var pos = editorText.search(re);
  var i = 0;
  while (pos != -1) {
    replaceTextPos(context, pos+i, oldTxt, newTxt);
    i = pos + newTxt.length + i;
    txt = context.getText().slice(i);
    pos = txt.search(re);
  }
}

function clearTextHighlight(context) {
  const editorText = context.getText();
  //context.formatText(0, editorText.length, 'background', false);
  const tagProS = `<prosody rate`;
  const tagProE = `%">`;
  const tagClose = `</prosody>`;
  var tmp = editorText;
  for(var i = 0; i <= editorText.length; i++) {
    var curS = tmp.search(tagProS);
    var curE = tmp.search(tagProE);
    var curClose = tmp.search(tagClose);
    //console.log("I = " + i);
    //console.log("tmp = " + tmp);
    //console.log("curS = " + curS + " curE = " + curE + " curClose = " + curClose);
    if(curS !== -1) {
      //console.log("1st IF : curS = " + curS + " curClose = " + curClose);
      if (localStorage.getItem("theme") && localStorage.getItem("theme") === "DARK")
        context.formatText(i, curS + i, {'background': false, 'color': 'white'});
      else
        context.formatText(i, curS + i, 'background', false);
      i = curS + i;
      //console.log("i = curS + i = " + i);
      var lengthOpenTag = (curE - curS)+tagProE.length;
      //console.log("lengthOpenTag = " + lengthOpenTag);
      context.formatText(i, lengthOpenTag, {'color': 'rgb(87, 112, 190)', 'italic': true});
      i = i + lengthOpenTag;
      //console.log("i = i + lengthOpenTag = " + i);
      var lengthBet = curClose - (curS + lengthOpenTag);
      //console.log("lengthBet = " + lengthBet);
      if (localStorage.getItem("theme") && localStorage.getItem("theme") === "DARK")
        context.formatText(i, lengthBet , {'background': false, 'color': 'white'});
      else
        context.formatText(i, lengthBet, 'background', false);
      i = i + lengthBet;
      //console.log("i = i + lengthBet = " + i);
      context.formatText(i, tagClose.length, {'color': 'rgb(87, 112, 190)', 'italic': true});
      i = i + tagClose.length;
      //console.log("i = i + tagClose.length = " + i);
    }
    else {
      if (localStorage.getItem("theme") && localStorage.getItem("theme") === "DARK")
        context.formatText(i, editorText.length - i, {'background': false, 'color': 'white'});
      else
        context.formatText(i, editorText.length - i, 'background', false);
      i = editorText.length;
    }
    //console.log("i before SLICE = " + i);
    tmp = editorText.slice(i+1);
    //console.log("SLICE = " + tmp);
  }
}

function insertTextTag(context, tag) {
  const cursorPosition = context.getSelection().index;
  context.insertText(cursorPosition, tag);
  context.setSelection(cursorPosition + tag.length);
}

function addTextTagAroundSelection(context, tagOpen, tagClose, rgb, italic) {
  var range = context.getSelection();
  if (range && range.length != 0) {
    context.insertText(range.index, tagOpen);
    context.insertText(range.index + range.length + tagOpen.length, tagClose);
    if(rgb !== null) {
      context.formatText(range.index, tagOpen.length, {'color': rgb, 'italic': true});
      context.formatText(range.index + range.length + tagOpen.length, tagClose.length, {'color': rgb, 'italic': italic});
    }
    context.setSelection(
      range.index + range.length + tagOpen.length + tagClose.length
    );
  }
}

function addTextOnSelectionOrPosition(context, insertText) {
  var range = context.getSelection();
  if (range) {
    if (range.length == 0)
      insertTextTag(context, insertText);
    else {
      context.deleteText(range.index, range.length);
      insertTextTag(context, insertText);
    }
  }
}

function insertElocSpeed() {
  addTextTagAroundSelection(this.quill, `<prosody rate="-20.00%">`, `</prosody>`, 'rgb(87, 112, 190)', true);
}

function insertStartReading() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertEmbed(cursorPosition, "image", startReadingImg);
  this.quill.setSelection(cursorPosition + 1);
}

/*
 * Custom toolbar component including the custom button and dropdowns
 */
const CustomToolbar = ({ setSelectedVoice, highlightWords, replaceWords, generateAudio }) => (
  <div id="toolbar">
    <button className="ql-insertStartReading customBarElem start-reading">
      <StartReading />
    </button>
    <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip id="button-tooltip">Écouter</Tooltip>}
    >
      <Button
        className="listen customBarElem"
        onClick={(e) => {
          e.preventDefault();
          generateAudio();
        }}
      >
        <img src={listenImg} alt="listen image" className="left-arrow"></img>
      </Button>
    </OverlayTrigger>
    <button className="ql-insertElocSpeed customBarElem">
      <ElocSpeed />
    </button>
    <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip id="button-tooltip">Surligner les mots communs au dictionnaire audio</Tooltip>}
    >
      <Button
        className="highlight customBarElem"
        onClick={(e) => {
          e.preventDefault();
          highlightWords();
        }}
      >
        <img src={highlightImg} alt="highlight image" className="left-arrow"></img>
      </Button>
    </OverlayTrigger>
    <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip id="button-tooltip">Remplacer les mots communs au dictionnaire audio</Tooltip>}
    >
    <Button
      className="replaceWithDico customBarElem"
      onClick={(e) => {
        e.preventDefault();
        replaceWords();
      }}
    >
      <img src={insertImg} alt="remplacer image" className="left-arrow"></img>
    </Button>
    </OverlayTrigger>
    <select className="ql-size customSize font-size">
      <option value="8px">8</option>
      <option value="10px">10</option>
      <option value="12px" selected>
        12
      </option>
      <option value="14px">14</option>
      <option value="16px">16</option>
    </select>
    <div className="voiceSelector">
      <span className="selectVoice">Voix :</span>
      <select
        className="voiceChoice"
        onChange={(e) => {
          console.log(e.target.value);
          setSelectedVoice(e.target.value);
        }}
      >
        <option value="Denise">Denise</option>
        <option value="Henri">Henri</option>
      </select>
    </div>
  </div>
);

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["8px", "10px", "12px", "14px", "16px", "18px"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);

class QuillEditor extends React.Component {
  constructor(props) {
    super(props);
    this.quill = null;
  }

  componentDidUpdate(prevProps) {
    //console.log(this.props);
    if (this.props.isHighlight !== prevProps.isHighlight) {
      this.quill.focus();
      const editor = this.quill.getEditor();
      if(this.props.isHighlight === true) {
        if(this.props.dictionary.length === 0) {
          fetch(process.env.REACT_APP_URL_BACKEND + "/api/dictionary", {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          })
            .then((response) => response.json())
            .then((data) => {
              this.props.setDictionary(data);
              data.forEach(elem => {markText(editor, elem.name, 'rgb(255, 255, 0)'); markText(editor, elem.audioText, 'rgb(189, 239, 165)');});
            })
            .catch(function (error) {
              console.log("Error fetching dictionary : " + error.message);
            });
        }
        else
          this.props.dictionary.forEach(elem => {markText(editor, elem.name, 'rgb(255, 255, 0)'); markText(editor, elem.audioText, 'rgb(189, 239, 165)');} );
      }
      else {
        clearTextHighlight(editor);
        this.props.dictionary.forEach(elem => markText(editor, elem.audioText, 'rgb(189, 239, 165)'));
      }
    }
    if (this.props.replaceWordFromDict !== prevProps.replaceWordFromDict && this.props.isHighlight === true) {
      this.quill.focus();
      const editor = this.quill.getEditor();
      this.props.dictionary.forEach(elem => replaceText(editor, elem.name, elem.audioText));
      this.props.setIsHighlight(false);
    }
    if (this.props.insertText !== prevProps.insertText) {
      this.quill.focus();
      const editor = this.quill.getEditor();
      addTextOnSelectionOrPosition(editor, this.props.insertText)
      this.props.reset(true);
    }
  }

  static modules = {
    toolbar: {
      container: "#toolbar",
      handlers: {
        insertStartReading: insertStartReading,
        insertElocSpeed: insertElocSpeed,
      },
    },
    history: {
      delay: 1000,
      maxStack: 500,
      userOnly: false,
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  static formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
  ];

  render() {
    //console.log(this.props);
    return (
      <div className="text-editor">
        <CustomToolbar
          setSelectedVoice={this.props.setSelectedVoice}
          highlightWords={this.props.highlightWords}
          replaceWords={this.props.replaceWords}
          generateAudio={this.props.generateAudio}
        />
        <ReactQuill
          ref={(el) => {
            this.quill = el;
          }}
          value={this.props.editorHtml}
          onChange={this.props.handleChange}
          placeholder={this.props.placeholder}
          modules={QuillEditor.modules}
          formats={QuillEditor.formats}
        />
      </div>
    );
  }
}

export default QuillEditor;

import logo from "../assets/image/logo_Add.png";
import logoWhite from "../assets/image/logo_Add_white.png";
import "./App.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import QuillEditor from "./QuillEditor";
import AudioParam from "./AudioParam.js";
import Dictionary from "./Dictionary.js";
import React, { useEffect, useState } from "react";
import { Book, CaretRight } from "react-bootstrap-icons";
import { ReactComponent as SoundIcon } from "../assets/image/sound.svg";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { ReactComponent as LigthBulbOn } from "../assets/image/lightbulbON.svg";
import { ReactComponent as LigthBulbOff } from "../assets/image/lightbulbOFF.svg";
import { ReactComponent as User } from "../assets/image/user.svg";
import { ReactComponent as Logout } from "../assets/image/logout.svg";
import { Link } from 'react-router-dom';

function App(props) {
  const [editorHtml, setEditorHtml] = useState("");
  const [selectedVoice, setSelectedVoice] = useState("Denise");
  const [audioFile, setAudioFile] = useState("");
  const [elocSpeed, setElocSpeed] = useState(0);
  const [dictBox, setDictBox] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const [replaceWordFromDict, setReplaceWordFromDict] = useState(false);
  const [insertText, setInsertText] = useState("");
  const [audioFormat, setAudioFormat] = useState(20);
  const [openAudioParams, setOpenAudioParams] = useState(false);
  const [fileName, setFileName] = useState("");
  const [dictionary, setDictionary] = useState([]);
  const [isAlaw, setIsAlaw] = useState(false);

  function downloadTextFile(audioFileName){
    // We add a return line at the end of each paragraph and then use the browser's parser to clean the text
    var div = document.createElement("div");
    div.innerHTML = editorHtml.replaceAll('</p>', '\n');
    const text = div.textContent || div.innerText || "";

    var blob = new Blob([text], {type: "text/plain"});
    const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          audioFileName + ".txt"
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
  }

  function downloadAudioFile() {
    const audioFileName = fileName === "" ? "TTSAudio" : fileName;
    downloadTextFile(audioFileName);
    fetch(process.env.REACT_APP_URL_BACKEND + '/api/downloadAudioFile', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        editor: editorHtml,
        voice: selectedVoice,
        elocSpeed: elocSpeed,
        audioFormat: audioFormat == 12 ? 11 : audioFormat,
        isAlaw: audioFormat == 12 ? true : false,
      })
    })
      .then(function (response) {
        response.arrayBuffer().then(function (buffer) {
          var blob = new Blob([buffer], {type: "audio/wav"});
          const link = document.createElement("a");
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute(
              "download",
              audioFileName + ".wav"
            );
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
      })
    })
      .catch((err) => {
        console.log(err);
      });
  }

  function highlightWords() {
    if(dictBox === false && isHighlight === false)
      setDictBox(true);
    setIsHighlight(!isHighlight);
  }

  function replaceWords() {
    console.log("REPLACE WORDS");
    setReplaceWordFromDict(!replaceWordFromDict);
  }

  function handleChange(html) {
    setEditorHtml(html);
  }

  const updateTheme = (theme) => {

    fetch(process.env.REACT_APP_URL_BACKEND + "/api/user/" + localStorage.getItem("email"), {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        theme: theme,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Theme updated : " + data);
        window.location.reload();
      })
      .catch(function (error) {
        console.log("Error updating the theme : " + error.message);
      });
  };

  function changeTheme() {
    if (localStorage.getItem("theme") && localStorage.getItem("theme") === "LIGHT") {
      localStorage.setItem("theme", "DARK");
      updateTheme("DARK");
    }
    else {
      localStorage.setItem("theme", "LIGHT");
      updateTheme("LIGHT");
    }
  }

  function generateAudio() {
    console.log("GENERATE");
    console.log("VOICE = " + selectedVoice);
    if(audioFormat == 12) {
      console.log("ALAW DETECTED");
    }
    fetch(process.env.REACT_APP_URL_BACKEND + "/api/audioStream", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        editor: editorHtml,
        elocSpeed: elocSpeed,
        voice: selectedVoice,
        audioFormat: audioFormat == 12 ? 11 : audioFormat,
        isAlaw: audioFormat == 12 ? true : false,
      }),
    })
      .then((response) => {
        response.arrayBuffer().then((buffer) => {
          console.log(buffer);
          var blob = new Blob([buffer], { type: "audio/wav" });
          const url = URL.createObjectURL(blob);
          setAudioFile(url);
        });
      })
      .catch(function (error) {
        console.log("PROBLEM WITH GENERATE AUDIO : " + error.message);
      });
  }

  return (
    <>
      <Container fluid>
        <Row className="header">
          <Col sm={2} md={2} xl={2}>
            {localStorage.getItem("theme") === "LIGHT" ?
              (<img src={logo} className="logoHeader" alt="logo" />)
              : (
                <img src={logoWhite} className="logoHeader" alt="logo" />
              )
            }
          </Col>
          <Col sm={8} md={localStorage.getItem("role") === "ADMIN" ? 7 : 8} xl={localStorage.getItem("role") === "ADMIN" ? 7 : 8}>
            <div className="titleHeader">Text to Speech</div>
          </Col>
          {localStorage.getItem("role") === "ADMIN" ? (
            <Col sm={1} md={1} xl={1}>
              <div class="center">
                <Link to="/admin">
                  {localStorage.getItem("theme") === "LIGHT" ? (
                      <User className="user-btn">
                      </User> ) :
                      (
                      <User className="user-btn invert-svg">
                      </User>
                      )
                    }
                </Link>
              </div>
            </Col>
          ) : null
          }
          <Col sm={1} md={1} xl={1}>
            <div class="center">
              <Link to="/logout">
                {localStorage.getItem("theme") === "LIGHT" ? (
                    <Logout className="logout-btn">
                    </Logout> ) :
                    (
                    <Logout className="logout-btn invert-svg">
                    </Logout>
                    )
                  }
              </Link>
            </div>
          </Col>
          <Col sm={1} md={1} xl={1}>
            <div class="center">
              {localStorage.getItem("theme") === "LIGHT" ? (
                <LigthBulbOff
                  className="lightbulb-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    changeTheme();
                  }}
                >
                </LigthBulbOff>)
                : (
                  <LigthBulbOn
                  className="lightbulb-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    changeTheme();
                  }}
                >
                </LigthBulbOn>)
              }
            </div>
          </Col>
        </Row>
        <Row className="content-row">
          <Col sm={12} md={dictBox ? 8 : 11} lg={dictBox ? 8 : 11}>
            <div className="file-name">
              <span className="selectFileName">Nom du fichier :</span>
              <span className="containerFileName">
                <input className="fileNameInput"
                  type="text"
                  id="name"
                  name="name"
                  onChange={(e) => {
                    setFileName(e.target.value);
                  }}
                />
              </span>
            </div>

            <QuillEditor
              placeholder={"Ecrivez ou collez votre message ici"}
              editorHtml={editorHtml}
              handleChange={handleChange}
              setSelectedVoice={setSelectedVoice}
              highlightWords={highlightWords}
              replaceWords={replaceWords}
              insertText={insertText}
              isHighlight={isHighlight}
              setIsHighlight={setIsHighlight}
              replaceWordFromDict={replaceWordFromDict}
              setReplaceWordFromDict={setReplaceWordFromDict}
              dictionary={dictionary}
              setDictionary={setDictionary}
              generateAudio={generateAudio}
              reset={(data) => {
                if (data) setInsertText("");
              }}
            />

            <Button
              className="generate-btn action-btn"
              onClick={(e) => {
                e.preventDefault();
                generateAudio();
              }}
            >
              Écouter
            </Button>
            <Row className="mt-4 audio-params-row">
              <Col sm={12} md={dictBox ? 6 : 5} lg={dictBox ? 6 : 5}>
                <AudioPlayer
                  src={audioFile}
                  customProgressBarSection={[
                    RHAP_UI.MAIN_CONTROLS,
                    RHAP_UI.DURATION,
                    RHAP_UI.PROGRESS_BAR,
                    RHAP_UI.CURRENT_TIME,
                  ]}
                  customControlsSection={[]}
                />
              </Col>
              <Col sm={12} md={dictBox ? 6 : 5} lg={dictBox ? 6 : 5} className="audio-params-col">
                <div className="audio-params-block-position">
                  <div className="audio-params-block">
                    <AudioParam
                      elocSpeed={elocSpeed}
                      setElocSpeed={setElocSpeed}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-4 audio-params-row">
              <Col sm={12} md={dictBox ? 6 : 5} lg={dictBox ? 6 : 5}>

                <div className="audioFormat">
                  <span className="selectAudioFormat">Format audio :</span>
                  <select
                    className="audioFormatChoice"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setAudioFormat(e.target.value);
                    }}
                  >
                    <option value={20}>48khz-16bit-pcm</option>
                    <option value={11}>8khz-16bit-pcm</option>
                    <option value={12}>8khz-8bit-ALAW</option>
                  </select>
                </div>
              </Col>
              <Col sm={12} md={{ span: dictBox ? 6 : 5 }} lg={dictBox ? 6 : 5}>
                <Button
                  className="action-btn download-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    downloadAudioFile();
                  }}
                >
                  Télécharger
                </Button>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={dictBox ? 4 : 1} lg={dictBox ? 4 : 1}>
            {!dictBox ? (
              <Button
                className="btn-circle-lg open-dict-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setDictBox(!dictBox);
                }}
              >
                <Book />
              </Button>
            ) : (
              <>
                <div className="title">
                  <span className="title-block">Dictionnaire audio</span>
                  <div className="blue-bar"></div>
                </div>

                <CaretRight
                  onClick={(e) => {
                    e.preventDefault();
                    setDictBox(!dictBox);
                  }}
                  className="close-dict-btn"
                />
              </>
            )}
            {dictBox ? (
              <Dictionary
                {...props}
                dictionary={dictionary}
                setDictionary={setDictionary}
                audioText={(data) => {
                  setInsertText(data);
                }}
              />
            ) : null}
          </Col>
        </Row>

        
      </Container>
    </>
  );
}
export default App;

import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../assets/image/logo_Add.png";
import logoWhite from "../assets/image/logo_Add_white.png";
import { Plus, TrashFill, PencilFill, Search } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";
import RemoveUser from "./RemoveUser";
import EditUser from "./EditUser";
import "./App.css";
import { ReactComponent as Home } from "../assets/image/home.svg";
import { Link } from 'react-router-dom';

const Admin = (props) => {
   const [didUpdate, setDidUpdate] = useState(false);
   const [show, setShow] = useState(false);
   const [users, setUsers] = useState([]);
   const [editModeModal, setEditModeModal] = useState("");
   const [showEditModal, setShowEditModal] = useState(false);
   const [deleteModeModal, setDeleteModeModal] = useState("");
   const [showDeleteModal, setShowDeleteModal] = useState(false);

   useEffect(() => {
      fetch(process.env.REACT_APP_URL_BACKEND + "/api/user", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((data) => {
           setUsers(data);
           setDidUpdate(false);
        })
        .catch(function (error) {
          console.log("Error getting users : " + error.message);
        });
    }, [didUpdate === true]);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const handleSubmit = (event) => {
      setDidUpdate(true);
      event.preventDefault();
      const form = event.currentTarget;
      setShow(false);
  
      fetch(process.env.REACT_APP_URL_BACKEND + "/api/user", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: form.email.value,
          passwordTmp: form.passwordTmp.value,
          role: form.role.value,
          firstCo: true,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("User created : " + data);
          setDidUpdate(false);
        })
        .catch(function (error) {
          console.log("Error creating the user : " + error.message);
        });
    };

    const handleChange = (state) => {
      // You can use setState or dispatch with something like Redux so we can use the retrieved data
      console.log('Selected Rows: ', state.selectedRows);
    };

    const columns = [
      {
         name: "Id",
         selector: "_id",
         omit: true,
       },
       {
         name: "Email",
         selector: "email",
         maxWidth: "275px",
         sortable: true,
         sortFunction: (rowA, rowB) =>
           rowA.email.localeCompare(rowB.email, undefined, {
             numeric: true,
             sensitivity: "base",
           }),
       },
       {
         name: "Rôle",
         selector: "role",
         maxWidth: "120px",
         sortable: true,
         sortFunction: (rowA, rowB) =>
           rowA.role.localeCompare(rowB.role, undefined, {
             numeric: true,
             sensitivity: "base",
           }),
       },
       {
         name: "Actions",
         cell: (row) => (
           <div>
             <PencilFill
               onClick={(e) => {
                 e.preventDefault();
                 setEditModeModal(row._id);
                 setShowEditModal(true);
               }}
               className="icon-btn"
             />
             <TrashFill
               onClick={(e) => {
                 e.preventDefault();
                 setDeleteModeModal(row._id);
                 setShowDeleteModal(true);
               }}
               className="icon-btn delete-btn"
             />
           </div>
         ),
         width: "90px",
       },
    ];

    return (
      <Container fluid>
         <Row className="header">
          <Col sm={2} md={2} xl={2}>
            {localStorage.getItem("theme") === "LIGHT" ?
              (<img src={logo} className="logoHeader" alt="logo" />)
              : (
                <img src={logoWhite} className="logoHeader" alt="logo" />
              )
            }
          </Col>
          <Col sm={6} md={6} xl={6}>
            <div className="titleHeader">Text to Speech</div>
          </Col>
          <Col sm={1} md={1} xl={1}>
            <div class="center">
              <Link to="/app">
                {localStorage.getItem("theme") === "LIGHT" ? (
                  <Home className="user-btn">
                  </Home> ) :
                  (
                  <Home className="user-btn invert-svg">
                  </Home>
                  )
                }
              </Link>
            </div>
         </Col>
        </Row>
        <Row className="content-row content-admin">
         <Col sm={12} md={6} lg={6}>
            <div className="title">
               <span className="title-block">Utilisateurs</span>
               <div className="blue-bar"></div>
            </div>
            <Button
            variant="primary"
            onClick={handleShow}
            className="btn-circle add-line-btn"
            >
               <Plus />
            </Button>

            <DataTable
            columns={columns}
            data={users}
            persistTableHead
            pagination
            paginationPerPage={10}
            paginationComponentOptions={{
               noRowsPerPage: true,
               rangeSeparatorText: "sur",
            }}
            />

            <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header closeButton>
               <Modal.Title>
                  <h5>Ajouter un nouvel utilisateur</h5>
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="email">
                  <Form.Label>
                     Email
                  </Form.Label>
                  <Form.Control as="textarea" rows={1} />
                  </Form.Group>
                  <Form.Group controlId="passwordTmp">
                  <Form.Label>
                     Mot de passe temporaire
                  </Form.Label>
                  <Form.Control as="textarea" rows={1} />
                  </Form.Group>
                  <Form.Group controlId="role">
                  <Form.Label>
                     Rôle
                  </Form.Label>
                  <Form.Control as="select" rows={1}>
                    <option>USER</option>
                    <option>ADMIN</option>
                  </Form.Control>
                  </Form.Group>
                  <Button className="postion-action-btn action-btn" type="submit">
                  Ajouter
                  </Button>
               </Form>
            </Modal.Body>
            </Modal>
            <EditUser
            {...props}
            user={users.filter((elt) => elt._id === editModeModal)}
            update={(data) => setDidUpdate(data)}
            showEditModal={showEditModal}
            show={(data) => setShowEditModal(data)}
            />
            <RemoveUser
            {...props}
            id={deleteModeModal}
            update={(data) => setDidUpdate(data)}
            showDeleteModal={showDeleteModal}
            show={(data) => setShowDeleteModal(data)}
            />
            </Col>
         </Row>
       </Container>
    );
}

export default Admin;
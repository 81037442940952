import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Plus, TrashFill, PencilFill } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";
import { ReactComponent as VoiceIcon } from "../assets/image/voice.svg";
import { ReactComponent as TextIcon } from "../assets/image/pen.svg";

const EditUser = (props) => {

  /*useEffect(()=> {
      console.log(props)
         setName(props.dictionary.filter(elt => elt._id === props.line).name)
  }, [props.showEditModal === true])*/

  const handleClose = () => props.show(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    props.show(false);

    fetch(process.env.REACT_APP_URL_BACKEND + "/api/dictionary/" + props.line[0]._id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: form.name.value,
        audioText: form.audioText.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Line updated : " + data);
        props.update(true);
      })
      .catch(function (error) {
        console.log("Error creating the line : " + error.message);
      });
  };

  return (
    <>
      <Modal
        show={props.showEditModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Modification de la ligne dans le dictionnaire</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label><TextIcon className="label-icon"/>Texte d'origine</Form.Label>
              <Form.Control
                defaultValue={props.line[0] ? props.line[0].name : null}
                as="textarea"
                rows={3}
              />
            </Form.Group>
            <Form.Group controlId="audioText">
              <Form.Label><VoiceIcon className="label-icon"/>Texte audio</Form.Label>
              <Form.Control
                defaultValue={props.line[0] ? props.line[0].audioText : null}
                as="textarea"
                rows={3}
              />
            </Form.Group>
            <Button className="postion-action-btn action-btn" type="submit">
              Modifier
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditUser;

import React, { useEffect, useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ReactComponent as Lock } from "../assets/image/unlock.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../assets/image/logo_Add.png";
import logoWhite from "../assets/image/logo_Add_white.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import jwt_decode from "jwt-decode"
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

const Login = (props) => {
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const [alertFailedAuth, setAlertFailedAuth] = useState(false);
  const [alertFailedCaptcha, setAlertFailedCaptcha] = useState(false);

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if(validateCaptcha(form.captcha.value) !== true)
      setAlertFailedCaptcha(true);
    else {
      fetch(process.env.REACT_APP_URL_BACKEND + "/api/authentication", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: form.email.value,
          password: values.password,
        }),
      })
      .then((response) => {
        if (response.status === 200) {
          response.json().then(function(data) {
            //console.log("Auth success = ");
            const decodedToken = jwt_decode(data.token);
            //console.log("id = " + decodedToken._id + " email = " + decodedToken.email + " role = " + decodedToken.role + " firstCo = " + decodedToken.firstCo + " theme = " + decodedToken.theme);
            localStorage.setItem("token", data.token);
            localStorage.setItem("email", decodedToken.email);
            localStorage.setItem("role", decodedToken.role);
            localStorage.setItem("firstCo", decodedToken.firstCo);
            if (decodedToken.theme) {
              localStorage.setItem("theme", decodedToken.theme);
              console.log("Setting the theme to " + decodedToken.theme);
            }
            if (decodedToken.firstCo === false) {
              //console.log("REDIRACT TO APP");
              props.history.push({
                pathname: "/app",
              });
            } else {
              //console.log("REDIRECT TO firstCo = " + localStorage.getItem("firstCo"));
              props.history.push({
                pathname: "/firstCo",
              });
            }
          })
        }
        else {
          setAlertFailedAuth(true);
          console.log("Authentication failed");
        }
      })
        .catch(function (error) {
          setAlertFailedAuth(true);
          console.log("Authentication failed : " + error.message);
        });
      }
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setAlertFailedAuth(false);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container fluid>
      <Row className="header">
        <Col sm={2} md={2} xl={2}>
          {(localStorage.getItem("theme") === "LIGHT" || localStorage.getItem("theme") === null) ?
              (<img src={logo} className="logoHeader" alt="logo" />)
              : (
                <img src={logoWhite} className="logoHeader" alt="logo" />
              )
            }
          </Col>
        <Col sm={7} md={7} xl={7}>
          <div className="titleHeader">Text to Speech</div>
        </Col>
      </Row>
      <div className="loginDiv">
        <span className="title-block">Connexion</span>
        <div className="blue-bar"></div>
        <div class="content-login">
            <div class="connexion">
              <Lock className="lock-img">
              </Lock>
            </div>
            <Form onSubmit={handleSubmit}>
              <TextField id="email" label="Email" className="input-email" autoComplete="username" name="email" onChange={(event) => {
                        setAlertFailedAuth(false);
                      }}/>
              <FormControl className="password">
                <InputLabel htmlFor="standard-adornment-password">Mot de passe</InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  autoComplete="current-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility-btn"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <TextField id="captcha" label="Captcha" className="input-captcha" name="captcha" autoComplete="off" onChange={(event) => {
                        setAlertFailedCaptcha(false);
                      }}/>
              <div className="captcha">
                <LoadCanvasTemplateNoReload  />
              </div>
              <Button type="submit" className="action-btn login-btn">
                Se connecter
              </Button>
            </Form>
        </div>
      </div>
      <div class="alert-auth"> 
        {alertFailedAuth
            ? (<Alert severity="error">Email ou mot de passe invalide. Merci de bien vouloir réessayer.</Alert>)
            : null}
      </div>
      <div class="alert-auth"> 
        {alertFailedCaptcha
            ? (<Alert severity="error">Captcha invalide, merci de bien vouloir réessayer.</Alert>)
            : null}
      </div>
  </Container>
  );
}

export default Login;
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Plus, TrashFill, PencilFill } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";

const EditModal = (props) => {

  const handleClose = () => props.show(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    props.show(false);

    var myBody = {};
    if(form.passwordTmp.value !== "") {
      myBody = {
        email: form.email.value,
        passwordTmp: form.passwordTmp.value,
        role: form.role.value,
        firstCo: true,
      }
    }
    else {
      myBody = {
        email: form.email.value,
        passwordTmp: form.passwordTmp.value,
        role: form.role.value,
      }
    }

    fetch(process.env.REACT_APP_URL_BACKEND + "/api/user/" + props.user[0]._id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(myBody),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("User updated : " + data);
        props.update(true);
      })
      .catch(function (error) {
        console.log("Error updating the user : " + error.message);
      });
  };

  return (
    <>
      <Modal
        show={props.showEditModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Modification de la ligne dans le dictionnaire</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="email">
              <Form.Label>
                  Email
              </Form.Label>
              <Form.Control defaultValue={props.user[0] ? props.user[0].email : null} as="textarea" rows={1} />
              </Form.Group>
              <Form.Group controlId="passwordTmp">
              <Form.Label>
                  Mot de passe temporaire
              </Form.Label>
              <Form.Control defaultValue={props.user[0] ? props.user[0].passwordTmp : null} as="textarea" rows={1} />
              </Form.Group>
              <Form.Group controlId="role">
              <Form.Label>
                  Rôle
              </Form.Label>
              <Form.Control defaultValue={props.user[0] ? props.user[0].role : null} as="select" rows={1}>
                <option>USER</option>
                <option>ADMIN</option>
              </Form.Control>
              </Form.Group>
              <Button className="postion-action-btn action-btn" type="submit">
              Modifier
              </Button>
            </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditModal;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import Admin from './components/Admin';
import FirstCo from './components/FirstCo';
import Login from './components/Login';
import Logout from './components/Logout';
import reportWebVitals from './reportWebVitals';
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const LightTheme = React.lazy(() => import("./themes/light"));
const DarkTheme = React.lazy(() => import("./themes/dark"));

const ThemeSelector = ({ children }) => {
  const CHOSEN_THEME = localStorage.getItem("theme") || "LIGHT";
  return (
    <>
      <React.Suspense fallback={<></>}>
        {CHOSEN_THEME === "LIGHT" && <LightTheme />}
        {CHOSEN_THEME === "DARK" && <DarkTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

const isAuthenticated = () => {
    if (localStorage.getItem("token") === null) return false

    const { exp } = jwt_decode(localStorage.getItem("token"));
    if (Date.now() >= exp * 1000) {
        return false;
    }
    return true;
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeSelector>
      <BrowserRouter>
        <Switch>
          <Route path="/app" render={() => (
            isAuthenticated() ? (
              <App />
            ) : (
              <Redirect to="/login"/>
            )
          )}/>
          <Route path="/admin" render={() => (
            isAuthenticated() ? (
              (localStorage.getItem("role") === "ADMIN" ? <Admin /> : <Redirect to="/app"/>)
            ) : (
              <Redirect to="/login"/>
            )
          )}/>
          <Route path="/firstCo" render={() => (
            isAuthenticated() ? (
              (localStorage.getItem("firstCo") === "true" ? <FirstCo /> : <Redirect to="/app"/>)
            ) : (
              <Redirect to="/login"/>
            )
          )}/>
          <Route path="/logout" component={Logout} />
          <Route path="/login" component={Login} />
          <Redirect exact from="*" to="/app" />
        </Switch>
      </BrowserRouter>
    </ThemeSelector>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
